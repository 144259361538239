<template>
  <div class="input-component">
    <div class="input-component__wrapper">
      <component :is="component">
        <Row align="center"
             justify="between"
             v-if="multiline && maxChars && maxChars > 0">
          <label :for="id"
                 class="popup-add-object__label">{{label}}</label>
          <span class="popup-add-object__textarea-counter">
            Осталось {{currentChars}} {{plural(currentChars,
            ['символ', 'символа', 'символов'])}}</span>
        </Row>
        <label :for="id" class="input-component__label"
               v-else>{{label}}</label>
        <textarea :id="id"
                  :maxlength="maxChars"
                  class="input-component__input"
                  v-if="multiline"
                  v-model="inputVal"/>
        <input :disabled="disabled"
               :id="id"
               :maxlength="maxChars"
               :type="type"
               class="input-component__input"
               v-else
               v-model="inputVal"/>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputComponent',
  props: {
    id: {
      required: true,
    },
    label: {
      required: true,
    },
    type: {
      default: () => 'text',
    },
    component: {
      default: () => 'Column',
    },
    multiline: {
      type: Boolean,
    },
    maxChars: {},
    value: {},
    disabled: {},
  },
  data() {
    return {
      currentChars: 0,
    };
  },
  created() {
    if (this.multiline && this.maxChars) this.currentChars = this.maxChars - this.value.length;
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line max-len,no-nested-ternary
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(string) {
        this.currentChars = this.maxChars - string.length;
        this.$emit('input', string);
      },
    },
  },
};
</script>
